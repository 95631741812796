import AxiosApi from "../../plugins/axiosApi";
import Vue from "vue";

export async function fetchItemsSaldo({ commit }, { modo = "", cliente = "", bases = "" } = {}) {
  try {
    if (modo === "consultar") {
      // Si el modo es "consultar", realiza la consulta al endpoint
      const axiosApi = new AxiosApi(Vue.prototype.$token);
      const queryString = `${cliente}/${bases}`;

      const response = await axiosApi.getById("saldoCuentaCorriente", queryString);

      if (response.data.status === "Success" && response.data.data) {
        const saldos = response.data.data;
        commit("setSaldo", saldos);
        return saldos;
      } else {
        console.error("Error en la respuesta del endpoint:", response.data.message);
        return null;
      }
    } else {
      // Modo por defecto (adquirir saldo)
      const itemsSaldo = dataItemsSaldo;
      commit("setSaldo", itemsSaldo);
      return itemsSaldo;
    }
  } catch (error) {
    console.error("Error al obtener datos de saldo:", error);
    return null;
  }
}

const dataItemsSaldo = [
	{
	  id: 1,
	  codigo: "P1",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN MJ",
	  precio: "",
	},
	{
	  id: 2,
	  codigo: "P2",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN ACARA",
	  precio: "",
	},
	{
	  id: 3,
	  codigo: "P3",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN ANMAC",
	  precio: "",
	},
	{
	  id: 4,
	  codigo: "P4",
	  nombre: "INGRESO DE DINERO A SU FAVOR EN ANSV",
	  precio: "",
	},
  ];
