export default {

  remove(state) {
    state.data = [];
    state.tipos = [];
    state.cantItemTotales = 0;
    state.cantItem = 0;
    state.subtotal = 0;
    state.gastosTransferencia = 0;
    state.gastosBancario = 0;
    state.total = 0;
  },
  addCantidad(state, item) {
    let index = state.data.findIndex(p => p.ItemCode === item.ItemCode);
    if (index !== -1) {
      state.data[index].unidades = (state.data[index].unidades || 0) + Number(state.data[index].SalesQtyPerPackUnit);
    }
    this.commit('carrito/calcularTotales');
  },
  removeCantidad(state, item) {
    let index = state.data.findIndex(p => p.ItemCode === item.ItemCode);
    if (index !== -1) {
      let unidades = state.data[index].unidades - Number(state.data[index].SalesQtyPerPackUnit);
      state.data[index].unidades = unidades >= 0 ? unidades : 0;
    }
    this.commit('carrito/calcularTotales');
  },
  removeItem(state, item) {
    let index = state.data.findIndex(p => p.ItemCode === item.ItemCode);
    if (index !== -1) {
      state.data.splice(index, 1);
    }
    this.commit('carrito/calcularTotales');
  },
  addOrUpdateItem(state, item) {
    let index = state.data.findIndex(p => p.ItemCode === item.ItemCode);
    if (index !== -1) {
      state.data[index].unidades = item.unidades;
    } else {
      state.data.push(item);
    }
    this.commit('carrito/calcularTotales');
  },


  addOrUpdateItemManual(carrito, item) {

      let index = -1;
      let unidadesPrevias;

      if (Array.isArray(carrito.data) && carrito.data.length > 0) {
        index = carrito.data.findIndex(p => p.ItemCode === item.ItemCode);
      }

      if (index !== -1) {

        carrito.data[index].unidades = item.unidades;


      } else {
        //si esta el carrito vacio
        if (!Array.isArray(carrito.data)){
          carrito.data = [item];
        }
        else
        {
          carrito.data.push(item);
        }
      }
      this.commit('carrito/calcularTotales');
    },

    validateDivisor(carrito, item) {


      let valor = item.unidades;
          let divisor = Number(item.SalesQtyPerPackUnit);
          let index = carrito.data.findIndex((p) => p.ItemCode === item.ItemCode);
          if (typeof valor === "undefined") {
            return true;
          }
          if (index !== -1) {
            if (!divisor || divisor === 0) {
              // Si el divisor es 0 o nulo, muestra el error específico en ese campo
              carrito.data[index].error = "El divisor no puede ser 0 o vacío";

              return false;
            } else {
              if (valor % divisor !== 0) {
                // Si el valor no es divisible por el divisor, muestra el mensaje de error
                //carrito.data[index].error = `El número ${valor} no es divisible por ${divisor}`;
                carrito.data[index].error  =   "Las unidades deben ser múltiplos de la cantidad mínima (" +divisor+")";
                carrito.data[index].disabled = true;


                return false;
              } else {
                // Si todo es correcto, borra el error
                carrito.data[index].error = "";
                carrito.data[index].disabled = false;

                return true;
              }
            }
          }
  },


  calcularTotales(state) {


    state.cantItemTotales = 0;
    state.cantItem = 0;
    state.subtotal = 0;
    state.total = 0;
    state.gastosTransferencia = parseFloat(0);
    state.gastosBancario =  parseFloat(0);
    state.gastosEnvio = parseFloat(0);
    state.gastos = {
      total:0,
      label:false
    };

    const sumasPorTipo = {};

    state.data.forEach(item => {


      if(item.ItemCode != "GT" && item.ItemCode != "GB" && item.ItemCode !== "FLE"){


          state.cantItemTotales += item.unidades;
          state.cantItem += 1;

          state.subtotal += item.unidades * item.priceList.Price;


          //state.gastos = Math.round(state.subtotal * 0.05, 1);

          // Sumar por TipoElementosId
            const tipoId = item.TipoElementosId;
            if (!sumasPorTipo[tipoId]) {
                sumasPorTipo[tipoId] = {
                    cantElementos: 0,
                    subtotal: 0,
                    subtotalDb: {
                      [process.env.VUE_APP_DB_ACARA]:0,
                      [process.env.VUE_APP_DB_MJ]:0,
                    }
                };
            }
            sumasPorTipo[tipoId].cantElementos += item.unidades;
            sumasPorTipo[tipoId].subtotal += item.unidades * item.priceList.Price;


            sumasPorTipo[tipoId].subtotalDb[item.db] += item.unidades * item.priceList.Price;

            if(item.Aportes){
              //sumo aportes al total de gastos
              sumasPorTipo[1].subtotal  += item.unidades * item.Aportes.priceList.Price;

            }

      }
      else
      {

            if(item.ItemCode == "GT")
            {
              state.gastosTransferencia += parseFloat(item.priceList.Price);
              sumasPorTipo[item.TipoElementosId].subtotalDb[item.db] += parseFloat(item.priceList.Price);

            }
            else if(item.ItemCode == "GB")
            {
              state.gastosBancario += parseFloat(item.priceList.Price);
              sumasPorTipo[item.TipoElementosId].subtotalDb[item.db] += parseFloat(item.priceList.Price);
            }
            else if (item.ItemCode === "FLE")
            {
              state.gastosEnvio += parseFloat(item.priceList.Price) * item.unidades;
              sumasPorTipo[item.TipoElementosId].subtotalDb[item.db] += parseFloat(item.priceList.Price) * item.unidades;
            }
      }

    });

    if (state.gastosBancario > 0) {
      state.gastos.total += parseFloat(state.gastosBancario);
      state.gastos.label = "Gastos Bancario ";
    }
    if (state.gastosTransferencia > 0) {
      state.gastos.total += parseFloat(state.gastosTransferencia);
      state.gastos.label = "Gastos Transferencia";
    }

    state.total = state.subtotal + state.gastos.total + parseFloat(state.gastosEnvio);

    state.sumasPorTipo = sumasPorTipo;

  },
  calcularGastos(state) {

    console.log(state.data);
  }



}
