export default {
  data: [], // Inicializado como un arreglo vacío
  tipos: [],
  cantItemTotales: 0,
  cantItem: 0,
  total: 0,
  gastosBancario: 0,
  gastosTransferencia: 0,
  subtotal: 0,
};
