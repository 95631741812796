import { apiRoute } from '../../helper/apiRoute';
import AxiosApi from './../../plugins/axiosApi.js';
import Vue from "vue";

export default {
  async fetchTransacciones({ commit, state }, { filters = {}, skip = 0, initialLoad = false, clientCardCode}) {
    try {
      const cardCode = clientCardCode;
      const entesDefault = [process.env.VUE_APP_DB_MJ, process.env.VUE_APP_DB_ACARA];
      const axiosApi = new AxiosApi(Vue.prototype.$token);

      //Carga inicial
      let queryParams = {
        CardCode: cardCode,
        skip: skip,
        Ente: entesDefault.join(','),
      };

      //Filtros
      if(!initialLoad) {
        if(filters.ref_pedido) {
          //console.log("Filtro con ref pedido: " +initialLoad+ filters.ref_pedido);
          queryParams.U_RefPedido = filters.ref_pedido;
        } else {
          //console.log("Filtro con ente + date: " + JSON.stringify(filters));
          queryParams.DocDateFrom = filters.startDate || "";
          queryParams.DocDateTo = filters.endDate || "";
          queryParams.Ente = filters.entidad && typeof filters.entidad === 'string' && filters.entidad.length > 0 ? filters.entidad : entesDefault.join(',');
        }
      }

      //console.log("query params: "+JSON.stringify(queryParams));
      const queryString = new URLSearchParams(queryParams).toString();
      //console.log("query string: "+queryString);
      const response = await axiosApi.getByQuery('transacciones', queryString);

      if (response.status !== 200) {
        throw new Error('Error en la respuesta de la API transacciones');
      }

      const transacciones = response.data.data;
      //console.log("TRANSACCIONES "+JSON.stringify(transacciones, null, 2));

      const dataTransacciones = transacciones.map(item => ({
        id: item.id,
        ref_pedido: item.U_RefPedido,
        cliente_id: item.CardName,
        ente: item.ente,
        entidad_id: item.entidad_id,
        tipo: item.tipo,
        estado: item.estado,
        total: item.DocTotal,
        metodo_pago: item.U_MPago,
        fecha: item.DocDate,
        Drafts: item.Drafts,
        Invoices: item.Invoices,
      }));

      commit("transacciones/setTransacciones", dataTransacciones, { root: true });

      return dataTransacciones;

    } catch (error) {
      console.error('Error al obtener las transacciones:', error.response ? error.response.data : error.message);
    }
  },

  async fetchTransaccionDetalle({ commit }, transaccionId) {
    try {
      const axiosApi = new AxiosApi(Vue.prototype.$token);
      const url = apiRoute.transaccionDetalle.replace('{id}', transaccionId);
      const response = await axiosApi.sendRequestExternal(url, 'get');

      if (response.status !== 200) {
        throw new Error('Error en la respuesta de la API de detalle de transacción: ' + response.status);
      }
      const jsonData = response.data;
      transaccionDetalleData(commit, jsonData);
    } catch (error) {
      //console.log(error, 'error');
      console.error('Error al obtener el detalle de la transacción:', error);
    }
  },
};

function transaccionData(commit, jsonData) {
  const data = jsonData;
  const dataTransacciones = [];
  data.forEach(item => {
    const transaccionObj = {
      id: item.id,
      ref_pedido: item.U_RefPedido,
      cliente_id: item.CardName,
      ente: item.ente,
      entidad_id: item.entidad_id,
      tipo: item.tipo,
      estado: item.estado,
      total: item.DocTotal,
      metodo_pago: item.U_MPago,
      fecha: item.DocDate,
    };
    dataTransacciones.push(transaccionObj);
  });

  commit("transacciones/setTransacciones", dataTransacciones, { root: true });
}

function transaccionDetalleData(commit, jsonData) {
  const data = jsonData.data;
  const dataTransaccionDetalle = [];

  data.forEach(item => {
    const detalleObj = {
      id: item.id,
      transaccion_id: item.transaccion_id,
      articulo: item.articulo,
      cantidad: item.cantidad,
      precio_unitario: item.precio_unitario,
      total: item.total,
    };

    dataTransaccionDetalle.push(detalleObj);
  });

  commit("transacciones/setTransaccionDetalle", dataTransaccionDetalle, { root: true });
}
